import jQuery from "jquery";

const $ = jQuery;

//scroll effect
const $win = $(window);

function scrolloff(event) {
    event.preventDefault();
}

$win.on('load', function () {
    if ($('html').hasClass('scroll_blocker') && $('.mv').hasClass('first')) {
        $('html, body').css('overflow', 'hidden');
        document.addEventListener('touchmove', scrolloff, {passive: false});
        setTimeout(function () {
            $('html, body').removeAttr('style');
            document.removeEventListener('touchmove', scrolloff, false);
        }, 2000);
    }
    $('.mv').addClass('active');
    $('.mv .lead').addClass('active');
    $('.header_index').addClass('active');
    $('.mv .scroller').addClass('active');
});

$win.on('load resize', function () {
    const wh = $win.height();
    const ww = window.innerWidth;

    $win.scroll(function () {
        let scroll = $(this).scrollTop();

        $('.slice').each(function () {
            let offset = $(this).offset().top;
            let trigger = offset - wh + 100;
            if (scroll > trigger) {
                $(this).addClass('active');
            }
        });
        $('.act,.slash').each(function () {
            let offset = $(this).offset().top;
            let trigger = offset - wh + 100;
            if (scroll > trigger) {
                $(this).addClass('active');
            }
        });
    });
});

//drawer menu
const drawer = $('body');

$('.buns').on("click", function () {
    drawer.toggleClass('drawer-open');
    $(this).toggleClass('active');
    $('.nav.mobile').toggleClass('active');
    $('.overlay').toggleClass('active');
    if (drawer.hasClass('drawer-open')) {
        //scroll禁止
        $('html, body').css('overflow', 'hidden');
        //scroll禁止(ios)
        document.addEventListener('touchmove', scrolloff, {passive: false});
    } else {
        $('html, body').removeAttr('style');
        document.removeEventListener('touchmove', scrolloff, false);
    }
});

//外部リンクにtarget blank付与
$(document).on("click",
    "a[href^=http]:not([target]):not([href*='" + location.hostname + "'])",
    function (event) {
        $(event.currentTarget).attr("target", "_blank")
    }
);

$('.page_contact .selector').on("click", function () {
    $('.radio').toggleClass('active');
    $('.trigger').toggleClass('active');
});

$('.page_contact .wpcf7-radio .wpcf7-list-item-label').on("click", function () {
    let text = $(this).text();
    $('.selected').text(text);
    $('.radio').removeClass('active');
    $('.trigger').removeClass('active');
});